import { render, staticRenderFns } from "./previewTablet.vue?vue&type=template&id=03121596&scoped=true&"
import script from "./previewTablet.vue?vue&type=script&lang=js&"
export * from "./previewTablet.vue?vue&type=script&lang=js&"
import style0 from "./previewTablet.vue?vue&type=style&index=0&id=03121596&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03121596",
  null
  
)

export default component.exports