<template>
  <div class="con">
    <div class="img_spot">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="baglog">
      <div class="smallrema" v-if="typeValue === 'formId'">
        <div v-if="previewdataD.logo" class="smallog">
          <img :src="imgSrc(previewdataD.logo)" alt="" />
        </div>
        <div
          v-if="previewdataD.bgimage && previewdataD.modeltype == 'generalMode'"
          class="samllgeneralMode"
        >
          <img :src="imgSrc(previewdataD.bgimage)" alt="" />
        </div>
        <h1 v-if="previewdataD.subject">
          {{ previewdataD.subject }}
        </h1>
        <el-input
          type="textarea"
          class="smallp"
          v-if="previewdataD.remark"
          :autosize="{ minRows: 0 }"
          v-model="previewdataD.remark"
          readonly
        ></el-input>
        <div
          v-if="previewdataD.formField && previewdataD.formField.length < 1"
          class="ziduan"
        ></div>
        <div v-if="previewdataD.formField && previewdataD.formField.length > 0">
          <div
            v-for="(item, index) in previewdataD.formField"
            :key="index"
            class="fieldtype"
          >
            <p><span v-if="item.required == '1'">*</span>{{ item.label }}</p>
            <el-input
              v-if="
                item.schemefieldType == 'P' ||
                item.schemefieldType == 'U' ||
                item.schemefieldType == 'S' ||
                item.schemefieldType == 'N' ||
                item.schemefieldType == 'V' ||
                item.schemefieldType == 'Y' ||
                item.schemefieldType == 'M' ||
                item.schemefieldType == 'MR' ||
                item.schemefieldType == 'E' ||
                item.schemefieldType == 'H' ||
                item.schemefieldType == 'E' ||
                item.schemefieldType == 'C' ||
                item.schemefieldType == 'c' ||
                item.schemefieldType == 'R' ||
                item.schemefieldType == 'SCORE'
              "
            >
            </el-input>
            <el-select
              :placeholder="$t('label.emailtocloudcc.select1')"
              v-if="item.schemefieldType == 'L' || item.schemefieldType == 'ct'"
            >
              <el-option
                v-show="item.codeValues"
                v-for="opt in item.codeValues"
                :key="opt.codevalue"
                :label="opt.codevalue"
                :value="opt.codevalue"
              ></el-option>
              <el-option
                v-show="item.codeValue"
                v-for="opt in item.codeValue"
                :key="opt.id"
                :label="opt.currencyName"
                :value="opt.currencyName"
              ></el-option>
            </el-select>
            <el-switch v-if="item.schemefieldType == 'B'"></el-switch>
            <el-time-picker
              placeholder=""
              v-if="item.schemefieldType == 'D' || item.schemefieldType == 'F'"
            ></el-time-picker>
            <el-input
              type="textarea"
              v-if="
                item.schemefieldType == 'X' ||
                item.schemefieldType == 'J' ||
                item.schemefieldType == 'A'
              "
            ></el-input>
            <el-checkbox-group
              v-if="item.schemefieldType == 'Q'"
              v-model="chechvalue"
            >
              <el-checkbox
                v-for="check in item.codeValues"
                :key="check.codevalue"
                :label="check.codevalue"
                name="type"
                >{{ check.codevalue }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div
          class="baiduapp"
          v-if="
            previewdataD.modeltype == 'positioningModel' &&
            previewdataD.center.lat
          "
        >
          <baidu-map
            class="baiduview"
            :center="previewdataD.center"
            zoom="19"
            :scroll-wheel-zoom="true"
          >
            <bm-marker :position="previewdataD.center"> </bm-marker>
          </baidu-map>
          <p v-if="previewdataD.address">
            {{ $t("AD") }}：{{ previewdataD.address }}
          </p>
        </div>
        <div class="baidusub">
          <button v-if="previewdataD.subject">{{ $t("label.submit") }}</button>
        </div>
      </div>
      <div
        class="smallrema"
        v-if="typeValue === 'letterId' && previewdataD.rtnurl"
      >
        <div v-html="previewdataD.rtnurl"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BaiduMap from "vue-baidu-map"; //引入百度地图组件库
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl",
});
export default {
  props: {
    previewdata: {
      type: Object,
      default: () => {},
    },
    typeValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewdataD: this.previewdata,
      checked: false,
      emailinput: "",
      fisrtname: "",
      lastname: "",
      Companyname: "",
      CountryRegion: "",
      Phonenumber: "",
      Industry: "",
      Employees: "",
      sport: "",
      options: [
        {
          value: "选项1",
          lebal: "Cloudcc",
        },
      ],
      chechvalue: [],
      token: this.$CCDK.CCToken.getToken(),
    };
  },
  methods: {
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-select .el-input .el-select__caret {
  display: flex;
  align-items: center;
}
::v-deep .el-select .el-input .el-select__caret {
  width: 100%;
}
.con {
  width: 1096px;
  height: 630px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #bebebe;
  position: relative;
  margin: 0 auto;
  margin-bottom: 52px;
  .more {
    height: 36px;
    background: #f5f8fa;
    border-bottom: 1px solid #dedcda;
    border-radius: 2px;
  }
  .perch {
    height: 129px;
    width: 95.6%;
    margin: 32px auto 0 auto;
    background: #eaf0f7;
    border-radius: 2px;
  }
  .downform {
    width: 95.6%;
    margin: 32px auto 0 auto;
    .desword > p:nth-child(1) {
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      font-size: 18px;
      color: #080707;
      letter-spacing: 0;
    }
    .desword > p:nth-child(2) {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      // margin-top: -20px;
    }
    .fullname {
      width: 100%;
      display: flex;
      margin-top: 20px;
      div {
        width: 90%;
        .el-input {
          width: 100%;
        }
      }
      div:nth-child(2) {
        margin-left: 20px;
      }
    }
    .employees {
      border-radius: 1.17px;
      span {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
      }
      .el-checkbox {
        margin-top: 20px;
      }

      ::v-deep .el-checkbox__inner {
        border: 1px solid #dedcda;
      }
    }
    .sport {
      margin-top: 20px;
    }
  }
}
.baglog {
  width: 1056px;
  height: 560px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #bebebe;
  margin: 50px 20px 20px 20px;
}
.img_spot {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 16px;
  left: 30px;
  margin: 0 auto;
  span {
    width: 10px;
    height: 10px;
    background: #d8d8d8;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
}
.smallrema {
  width: 80%;
  margin: 0 auto;
  height: 90%;
  margin-top: 5%;
  overflow: auto;
  .smallog {
    width: 100%;
    height: 200px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .samllgeneralMode {
    width: 100%;
    margin-top: 15px;
    background: palegoldenrod;
    img {
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
      object-fit: contain;
    }
  }
  h1 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 35px;
    font-weight: bold;
    margin: 0;
    margin-top: 15px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis; //文本溢出显示省略号
    white-space: nowrap;
  }
  .smallp {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
    ::v-deep .el-textarea__inner {
      border: none;
      resize: none;
      padding: 0;
    }
  }
  .ziduan {
    width: 100%;
    height: 450px;
    background: #ddd;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .fieldtype {
    margin-bottom: 20px;
    pointer-events: none;
    p {
      margin: 0px 0px 6px;
      font-size: 14px;
      color: #33475b;
      span {
        color: red;
      }
    }
    ::v-deep .el-input__inner {
      background: #f5f8fa;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
    }
    ::v-deep .el-textarea__inner {
      background: #f5f8fa;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
    }
  }
  .baiduapp {
    width: 100%;
    .baiduview {
      margin: 10px auto;
      width: 100%;
      height: 300px;
    }
    p {
      width: 100%;
    }
  }
  .baidusub {
    display: flex;
    justify-content: center;
    button {
      border: none;
      outline: none;
      color: #fff;
      background: #2d6cfc;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 2px 10px;
      zoom: 1.3;
    }
  }
}
.smallrema::-webkit-scrollbar {
  width: 0px;
}
</style>