<template>
  <div class="optiondetail">
    <div class="optionmain">
      <div class="intro">
        <span>{{ $t("label.happen_submit") }}</span>
        <!-- c142 -->
         <el-tooltip class="item" effect="dark" :content="$t('c142')" placement="top-start">
            <svg class="icon" aria-hidden="true"><use href="#icon-tip"></use></svg>
          </el-tooltip>
        <br />
        <span>({{ $t("label.approvalstep5.required") }})</span
        >
      </div>
      <div>
        <el-radio-group v-model="radio">
          <el-radio :label="1" @click.native="radio1">{{
            $t("label.display_thank")
          }}</el-radio>
          <el-radio :label="2" @click.native="radio2">{{
            $t("label.another_page")
          }}</el-radio>
          <!-- <el-radio :label="3" @click.native="radio3">{{
            $t("label.campaign.channel.ticket.title")
          }}</el-radio> -->
        </el-radio-group>
      </div>
      <div  v-if="inputshow" class="inputwindow">
        <E @editorContent="editorContent" :contents="textera" />
      </div>
      <!-- 感谢信息是必填项 -->
        <span class="error_box" v-if="isError&&inputshow">*{{ $t("c141") }}</span>
      <div class="page_" v-if="page_show">
      <el-input
        :class="isUrlError?'red_border':'old_border'"
        v-model="anotherpage"
        size="small"
        :placeholder="$t('component_setup_iframe_label_inputurl')"
      ></el-input>
      </div>
      <!-- <div class="qrcodecon" v-show="qrcodeshow&&!page_show&&!inputshow">
        <p @click="viewex = true">
          {{ $t("label.searchlayout.view.example") }}
        </p>
      </div> -->
      <div class="exdialog">
        <el-dialog
          style="height: 70%; overflow: visible"
          top="3%"
          :title="$t('label.campaign.channel.ticket.title')"
          :visible.sync="viewex"
          width="30%"
        >
          <div class="ticketBox">
            <div class="tag">
              {{ $t("label.campaign.channel.ticket.title") }}
            </div>
            <div class="box_top">
              <div class="top_title">
                <h3>{{ $t("label.marketcloud_seminar") }}</h3>
              </div>
            </div>
            <div class="box_content">
              <div class="content_code">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-qrcode-demo"></use>
                </svg>
              </div>
            </div>
            <div class="box_bottom">
              <div class="bottom_text">
                <span>{{ $t("label.campaign.channel.ticket.mark") }}</span>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>

      <div class="qrcode">
        <el-dialog
          :title="$t('label.use_QRcode')"
          :visible.sync="dialogVisible"
          width="30%"
        >
          <span>{{ $t("label.qrcode_signin") }}</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="(dialogVisible = false), returnfisrt()">{{
              $t("label.emailsync.button.cancel")
            }}</el-button>
            <el-button
              type="primary"
              @click="(dialogVisible = false), staynext()"
              >{{ $t("label.use_QRcode") }}</el-button
            >
          </span>
        </el-dialog>
      </div>
      <!-- <div class="nav">
        <span>Follow-up options</span>
       
      </div> -->
      <!-- <div class="needcheck">
        <el-checkbox v-model="checked">
          <span>
            Send submission email notifications to the contact's owner.
          </span>
        </el-checkbox>
        <span>Learn more</span>
      </div> -->
      <!-- <div class="sendemail"> -->
        <!-- 发送提交邮件通知 -->
        <!-- <span>{{ $t("c842") }} </span> -->
        <!-- c143 -->
          <!-- <el-tooltip class="item" effect="dark" :content="$t('c143')" placement="top-start">
            <svg class="icon" aria-hidden="true"><use href="#icon-tip"></use></svg>
          </el-tooltip>
      </div> -->
      <!-- <div class="checkmore">
        <el-select
          v-model="value1"
          multiple
          filterable
          :placeholder="$t('label.emailtocloudcc.select1')"
          :popper-append-to-body="false"
        > -->
          <!-- <div class="searchtext">
            <input
              type="text"
              class="search-text"
              v-model="searchValue"
              :placeholder="$t('report.label.pleaseinput')"
              @keyup="searchList"
            />
          </div> -->
          <!-- <el-option
            v-for="item in option"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>
  </div>
</template>
<script>
import * as marketChannel from "./api"; //api
import E from "@/views/marketForm/components/editer/index.vue";
export default {
  components: {
    E,
  },
  data() {
    return {
      viewex: false,
      qrcodeshow: false,
      dialogVisible: false,
      inputshow: true,
      page_show: false,
      anotherpage: "",
      editor: null,
      checked: false,
      radio: 1,
      // qrcodenum: 0,
      options: [],
      option: [],
      value1: [],
      text: "",
      textera: this.$i18n.t("c414"),//谢谢您的填写
      emails: [],
      searchValue: "",
      isError:false,
      isUrlError:false,
    };
  },
  created() {
    this.getsubject();
    this.getEmail();
  },
  methods: {
    //富文本内容
    editorContent(val) {
      this.text = val;
    },
    staynext() {
      this.qrcodeshow = true;
    },
    returnfisrt() {
      this.radio = 1;
      this.inputshow = true;
      this.page_show = false;
    },
    // radio3() {
    //   this.inputshow = false;
    //   this.page_show = false;
    //   this.isUrlError = false;
    //     this.dialogVisible = true;
    // },
    radio2() {
      this.inputshow = false;
      this.page_show = true;
      this.qrcodeshow = false
      this.isUrlError = false;
    },
    radio1() {
      this.inputshow = true;
      this.page_show = false;
      this.qrcodeshow = false
      this.isUrlError = false;
    },
    //编辑回显
    getsubject() {
      if (this.$store.state.creatform.itemDataId) {
        let obj = {
          id: this.$store.state.creatform.itemDataId,
        };
        marketChannel
          .getFormdetail(obj)
          .then((res) => {
            if (res.data.rtntype == "thanksmessage") {
              this.radio = 1;
              this.inputshow = true;
              this.page_show = false;
              this.textera = res.data.rtnurl;
              this.value1 = JSON.parse(res.data.userEmailIds);
            }
            if (res.data.rtntype == "redirectpage") {
              this.radio = 2;
              this.inputshow = false;
              this.page_show = true;
              this.anotherpage = res.data.rtnurl;
              this.value1 = JSON.parse(res.data.userEmailIds);
            }
            // if (res.data.rtntype == "qrcode") {
            //   this.radio = 3;
            //   this.inputshow = false;
            //   this.page_show = false;
            //   this.qrcodeshow = true;
            //   this.value1 = "";
            //   this.anotherpage = res.data.rtnurl;
            // }
          })
          .catch(() => {
            return false;
          });
      }
    },
    //获取用户邮箱
    getEmail() {
      marketChannel
        .getUsersEmail()
        .then((res) => {
          this.options = res.data.emailList;
          this.option = res.data.emailList;
        })
        .catch(() => {
          return false;
        });
    },
    searchList() {
      if (this.searchValue == "") {
        this.option = this.options;
      } else {
        this.option = [];
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].indexOf(this.searchValue) >= 0) {
            this.option.push(this.options[i]);
          }
        }
      }
      if (this.option.length == 0) {
        this.option = [this.$i18n.t("vue_label_SMS_nodata")];
      }
    },
  },
  watch:{
    anotherpage(nval){
      if(nval){
        this.isUrlError = false
      }else{
        this.isUrlError = true
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.icon {
  display: inline-block;
  margin-left: 4px;
}

::v-deep .el-tag--light {
  height: 30px;
  background-color: #fff;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #3e3c3c;
  letter-spacing: 0;
  line-height: 28px;
  border: 1px solid #dedcda;
  border-radius: 3px;
}

.el-radio {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #3e3c3c;
  letter-spacing: 0;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #3e3c3c;
}
html,
body {
  width: 100%;
  max-height: 100%;
}
.tip {
  margin-left: 10px;
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.ticketBox {
  .tag {
    text-align: center;
    font-family: "Microsoft Yahei";
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .box_top {
    width: 98%;
    background: url(https://testdev.cloudcc.com/platform/qrcode/images/bg_top.jpg)
      repeat-x left top;
    background-color: #ffffff;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-box-align: center;
    border-bottom: 1px dashed #cccccc;
    .top_title {
      width: 100%;
    }
    .top_title h3 {
      color: #52a3fe;
      font-weight: 400;
      /* line-height: 2.2rem; */
      padding-top: 20px;
    }
    .top_title p {
      font-size: 0.8rem;
      color: #666666;
    }
  }
  .box_content {
    background-color: white;
    padding-bottom: 10px;
    padding-top: 10px;
    height: initial;
    width: 98%;
    margin: auto;
    .content_code {
      text-align: center;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      width: 90%;
      margin: auto;
      .icon {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 195px;
        height: 195px;
      }
    }
  }
  .box_bottom {
    width: 98%;
    background: url(https://testdev.cloudcc.com/platform/qrcode/images/bg_bottom.jpg)
      repeat-x left bottom;
    background-color: white;
    border-top: 1px dashed #cccccc;
    margin: auto;
    .bottom_text {
      padding: 0 1rem;
      padding-top: 10px;
      padding-left: 10px;
      padding-bottom: 15px;
      margin: auto;
      .bottom_text span {
        font-size: 0.8rem;
      }
    }
  }
}

.optiondetail {
  display: flex;
  justify-content: center;
  height: 740px;
  overflow: auto;
  // margin:0 10px 10px 10px;
  border: 1px solid #dedcda;
  border-top: none;
  background: #ffffff;
  .optionmain {
    height: 100%;
    margin: 0 1px;
    border-top: none;
    border-radius: 0 0 3px 3px;
    background-color: #fff;
    .error_box{
      font-size: 17px;
font-family: MicrosoftYaHei;
color: #FB0000;
line-height: 22px;
    }
    .intro {
      span:nth-child(1) {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #666666;
        letter-spacing: 0;
        display: inline-block;
        margin-top: 40px;
      }
      span:nth-child(4) {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #333;
        letter-spacing: 0;
        display: inline-block;
        margin: 5px 0 10px 0;
      }
    }
    .inputwindow {
      width: 477px;
      min-height: 247px;
      background: #ffffff;
      // border: 1px solid #dedcda;
      // border-bottom: 1px solid #dedcda;
      border-radius: 3px;
      margin-top: 30px;
      // ::v-deep .toolbar{
      //   height:56px;
      //       background-color: #fff;
      // }
    }
    .page_ {
      ::v-deep .red_border{
        .el-input__inner{
          border: 1px solid red;
        }
      }
      ::v-deep .old_border{
        .el-input__inner{
          border: 1px solid #dcdfe6;
        }
      }
      ::v-deep .el-input__inner {
        min-height: 50px !important;
        width: 477px;
        margin-top: 30px;
      }
    }
    .qrcodecon {
      margin-top: 30px;
      p:nth-child(1) {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #2D6CFC;
        letter-spacing: 0;
        line-height: 19px;
        cursor: pointer;
      }
    }
    .exdialog {
      p {
        font-size: 18px;
        text-align: center;
      }
    }
    .nav {
      margin-top: 20px;
    }
    .needcheck {
      margin-top: 10px;
      margin-bottom: 30px;
      span:nth-child(1) {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
      }
      span:nth-child(2) {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #3071ca;
        letter-spacing: 0;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .checkmore {
      width: 444px;
      ::v-deep .el-select-dropdown__list {
        padding-top: 0px !important;
      }
      ::v-deep .el-input__inner {
        width: 444px;
        min-height: 30px !important;
      }
      ::v-deep .el-icon-close {
        margin-left: 10px !important;
      }
      ::v-deep .el-select-dropdown {
        // position: fixed !important;
        // top: 301px !important;
        // left: 240px !important;
        max-height: 180px !important;
      }
      // ::v-deep .el-select > .el-input {
      //   height: 200px !important;
      // }
      ::v-deep .el-scrollbar__wrap {
        max-height: 180px !important;
      }
      // .el-select-dropdown.is-multiple
      //   .el-select-dropdown__item.selected::after {
      //   position: static !important;
      //   float: right;
      // }
    }
    .sendemail {
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }
}
.searchtext {
  display: inline-block;
  width: 100%;
  height: 30px;
  background: #ccc;
  margin: 0;
  .search-text {
    width: 90%;
    margin-left: 12px;
    height: 30px;
    margin-top: 5px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding-left: 15px;
  }
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  position: static !important;
  float: right;
}
</style>