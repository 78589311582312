var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabletprevire"},[_c('div',{staticClass:"cicle"}),_c('div',{staticClass:"smalldiv"},[_c('div',{staticClass:"smallup"}),(_vm.typeValue === 'formId')?_c('div',{staticClass:"smalldown"},[(_vm.previewdataD.logo)?_c('div',{staticClass:"smallog"},[_c('img',{attrs:{"src":_vm.imgSrc(_vm.previewdataD.logo),"alt":""}})]):_vm._e(),(_vm.previewdataD.bgimage && _vm.previewdataD.modeltype == 'generalMode')?_c('div',{staticClass:"samllgeneralMode"},[_c('img',{attrs:{"src":_vm.imgSrc(_vm.previewdataD.bgimage),"alt":""}})]):_vm._e(),(_vm.previewdataD.subject)?_c('h1',[_vm._v(" "+_vm._s(_vm.previewdataD.subject)+" ")]):_vm._e(),(_vm.previewdataD.remark)?_c('el-input',{staticClass:"smallp",attrs:{"type":"textarea","autosize":{ minRows: 0 },"readonly":""},model:{value:(_vm.previewdataD.remark),callback:function ($$v) {_vm.$set(_vm.previewdataD, "remark", $$v)},expression:"previewdataD.remark"}}):_vm._e(),(_vm.previewdataD.formField && _vm.previewdataD.formField.length < 1)?_c('div',{staticClass:"ziduan"}):_vm._e(),(_vm.previewdataD.formField && _vm.previewdataD.formField.length > 0)?_c('div',_vm._l((_vm.previewdataD.formField),function(item,index){return _c('div',{key:index,staticClass:"fieldtype"},[_c('p',[(item.required == '1')?_c('span',[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(item.label))]),(
              item.schemefieldType == 'P' ||
              item.schemefieldType == 'U' ||
              item.schemefieldType == 'S' ||
              item.schemefieldType == 'N' ||
              item.schemefieldType == 'V' ||
              item.schemefieldType == 'Y' ||
              item.schemefieldType == 'M' ||
              item.schemefieldType == 'MR' ||
              item.schemefieldType == 'E' ||
              item.schemefieldType == 'H' ||
              item.schemefieldType == 'E' ||
              item.schemefieldType == 'C' ||
              item.schemefieldType == 'c' ||
              item.schemefieldType == 'R' ||
              item.schemefieldType == 'SCORE'
            )?_c('el-input'):_vm._e(),(item.schemefieldType == 'L' || item.schemefieldType == 'ct')?_c('el-select',{attrs:{"placeholder":_vm.$t('label.emailtocloudcc.select1')}},[_vm._l((item.codeValues),function(opt){return _c('el-option',{directives:[{name:"show",rawName:"v-show",value:(item.codeValues),expression:"item.codeValues"}],key:opt.codevalue,attrs:{"label":opt.codevalue,"value":opt.codevalue}})}),_vm._l((item.codeValue),function(opt){return _c('el-option',{directives:[{name:"show",rawName:"v-show",value:(item.codeValue),expression:"item.codeValue"}],key:opt.id,attrs:{"label":opt.currencyName,"value":opt.currencyName}})})],2):_vm._e(),(item.schemefieldType == 'B')?_c('el-switch'):_vm._e(),(item.schemefieldType == 'D' || item.schemefieldType == 'F')?_c('el-time-picker',{attrs:{"placeholder":""}}):_vm._e(),(
              item.schemefieldType == 'X' ||
              item.schemefieldType == 'J' ||
              item.schemefieldType == 'A'
            )?_c('el-input',{attrs:{"type":"textarea"}}):_vm._e(),(item.schemefieldType == 'Q')?_c('el-checkbox-group',_vm._l((item.codeValues),function(check){return _c('el-checkbox',{key:check.codevalue,attrs:{"label":check.codevalue,"name":"type"}},[_vm._v(_vm._s(check.codevalue))])}),1):_vm._e()],1)}),0):_vm._e(),(
          _vm.previewdataD.modeltype == 'positioningModel' &&
          _vm.previewdataD.center.lat
        )?_c('div',{staticClass:"baiduapp"},[_c('baidu-map',{staticClass:"baiduview",attrs:{"center":_vm.previewdataD.center,"zoom":"19","scroll-wheel-zoom":true}},[_c('bm-marker',{attrs:{"position":_vm.previewdataD.center}})],1),(_vm.previewdataD.address)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("AD"))+"："+_vm._s(_vm.previewdataD.address)+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"baidusub"},[(_vm.previewdataD.subject)?_c('button',[_vm._v(_vm._s(_vm.$t("label.submit")))]):_vm._e()])],1):_vm._e(),(_vm.typeValue === 'letterId' && _vm.previewdataD.rtnurl)?_c('div',{staticClass:"smalldown"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.previewdataD.rtnurl)}})]):_vm._e()]),_c('div',{staticClass:"cicle_bottom"})])}
var staticRenderFns = []

export { render, staticRenderFns }