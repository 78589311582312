<template>
  <div class="main">
    <div class="nav">
      <div class="sel_box">
        <span>{{ $t("pagecreator.button.preview") }}</span>
        <el-select v-model="typeValue">
          <el-option
            v-for="item in typeValueOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-radio-group v-model="previewradio" size="small" class="preview_radio">
        <el-radio-button
          :label="$t('label.desktop')"
          @click.native="condesktop()"
        ></el-radio-button>
        <el-radio-button
          :label="$t('label.tablet')"
          @click.native="contablet()"
        ></el-radio-button>
        <el-radio-button
          :label="$t('label.mobile')"
          @click.native="conmobile()"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div class="preview">
      <previewDesktop
        v-if="desktopshow"
        :previewdata="previewdata"
        :typeValue="typeValue"
      />
      <previewTablet
        v-if="tabletshow"
        :previewdata="previewdata"
        :typeValue="typeValue"
      />
      <previewMobile
        v-if="mobileshow"
        :previewdata="previewdata"
        :typeValue="typeValue"
      />
    </div>
  </div>
</template>

<script>
import previewDesktop from "@/views/marketForm/createFrom/Previews/previewDesktop.vue";
import previewTablet from "@/views/marketForm/createFrom/Previews/previewTablet.vue";
import previewMobile from "@/views/marketForm/createFrom/Previews/previewMobile.vue";
import * as marketChannel from "./api"; //api
export default {
  components: {
    previewDesktop,
    previewTablet,
    previewMobile,
  },
  props: {
    //感谢页内容
    thankContent: {
      type: String,
      default: "",
    },
    //是否显示感谢页
    inputshow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      desktopshow: true,
      tabletshow: false,
      mobileshow: false,
      previewradio: this.$i18n.t("label.desktop"),
      previewdata: {},
      typeValue: "formId",
      typeValueOptions: [
        {
          value: "formId",
          label: this.$i18n.t("label.form"),//表单
        },
      ],
    };
  },
  created() {
    this.getform();
  },
  methods: {
    condesktop() {
      this.desktopshow = true;
      this.tabletshow = false;
      this.mobileshow = false;
    },
    contablet() {
      this.desktopshow = false;
      this.tabletshow = true;
      this.mobileshow = false;
    },
    conmobile() {
      this.desktopshow = false;
      this.tabletshow = false;
      this.mobileshow = true;
    },
    //获取基本信息
    getform() {
      if (this.inputshow) {
        // c187
        this.typeValueOptions.push({
          value: "letterId",
          label: this.$i18n.t("c187"),
        });
      }
      let obj = {
        id: this.$store.state.creatform.itemDataId,
      };
      let yudata = {};
      let arr = [];
      marketChannel.getFormInfo(obj).then((res) => {
        if (res.returnCode == "1") {
          if (res.data.formField) {
            for (let i = 0; i < res.data.formField.length; i++) {
              // if (res.data.formField[i].seq == i) {
              //   arr.push(res.data.formField[i])
              // }
              arr[res.data.formField[i].seq] = res.data.formField[i];
            }
          }
          yudata.formField = arr;
          marketChannel.getFormdetail(obj).then((res) => {
            if (res.result) {
              yudata.bgimage = res.data.bgimage;
              yudata.logo = res.data.logo;
              yudata.remark = JSON.parse(res.data.remark);
              yudata.modeltype = res.data.modeltype;
              yudata.subject = res.data.subject;
              yudata.center = {
                lng: res.data.longitude,
                lat: res.data.latitude,
              };
              yudata.address = res.data.address;
              if (res.data.rtnurl) {
                yudata.rtnurl = res.data.rtnurl;
              } else {
                yudata.rtnurl = this.thankContent;
              }
              this.previewdata = yudata;
            }
          });
        }
      });
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-radio-button--small .el-radio-button__inner {
  width: 88px;
  height: 30px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  line-height: 30px;
  padding: 0;
}
::v-deep .el-input__inner {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
}
::v-deep .el-input__icon {
  line-height: 30px;
}

.main {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  margin-bottom: 10px;
  background-color: #fff;

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 32px 24px;
    ::v-deep .el-radio-group {
      width: 264px;
      span {
        font-size: 12px;
      }
    }
    .sel_box {
      span {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-right: 14px;
      }
      input {
        width: 227px;
        height: 30px;
      }
    }
    p {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #080707;
      letter-spacing: 0;
      margin-top: 10px;
    }
  }
}
.preview {
  overflow: hidden;
  margin-bottom: 54px;
}
::v-deep .preview_radio {
  .el-radio-button__inner {
    background: #fff;
    color: #000;
    box-shadow: none;
  }
  .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background: #f4f8ff;
    color: #2d6cfc;
  }
}
</style>